<template>
  <v-app-bar
    app
    elevation="4"
    :style="headerStyles"
    class="mb-2 pa-0 pl-10 header"
    :height="headerHeight"
  >
    <v-img
      alt="Allmetrix"
      src="../assets/logo_full.svg"
      max-height="61"
      max-width="339"
      v-if="$vuetify.breakpoint.lgAndUp"
    />
    <v-img
      alt="Allmetrix"
      src="../assets/logo.svg"
      max-height="38"
      max-width="208"
      v-if="$vuetify.breakpoint.mdAndDown"
    />
    <v-spacer></v-spacer>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-tabs
        slider-color = "rgba(0,0,0,0)"
        color = "primary"
        v-model="activeTab"
      >
        <v-tab href="#tab-top" @click="scrollTo('anchorTop')">About us</v-tab>
        <v-tab href="#tab-solutions" @click="scrollTo('anchorSolutions')">Solutions</v-tab>
        <v-tab href="#tab-news" @click="scrollTo('anchorNews')">News</v-tab>
        <v-tab href="#tab-contacts" @click="scrollTo('anchorContacts')">Contacts</v-tab>
      </v-tabs>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="ml-2 mr-1"
            color="primary"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list style="cursor: pointer">
          <v-list-item @click="scrollTo('anchorTop')">
            <v-list-item-title>About us</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('anchorSolutions')">
            <v-list-item-title>Solutions</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('anchorNews')">
            <v-list-item-title>News</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo('anchorContacts')">
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<style>
.header {
  margin: 0 auto; 
  max-width: 1920px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #0F1011!important;
}
.v-tabs a {font-size: 24px;}
</style>

<script>

export default {
  data: () => ({
    activeTab: null,
    scrollTop: 0,
    pageName: "",
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollTo(anchor) {
      document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    },
  },
  computed: {
    headerHeight() {
      let height = 112;
      if (this.$vuetify.breakpoint.smAndDown) height = 92;
      console.log(height)
      return height;
    },
    headerStyles() {
      let bgColor = "white";
      if (this.scrollTop == 0) bgColor = "white";
      return {
        "background-color": bgColor + "!important",
      };
    },

  },
  watch: {
  },
};
</script>
