<template>
  <v-app class="bg">
    <v-main>
      <app-header />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader";
export default {
  name: "App",
  components: {AppHeader},
  mounted() {
    // eslint-disable-next-line no-undef
/*     mh_px(801, {
      ym_uid: this.check_cookie_name("_ym_uid"),
      action: "view",
    }); */
  },
  methods: {
    check_cookie_name: function (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return "null";
    },
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,400;0,700;1,500;1,600&display=swap');
.v-main {
  font-family: 'Exo 2', sans-serif!important;
}
.bg {
  background-color: #20B4BD!important;
/*   background: url("assets/back.jpg") no-repeat center center fixed !important;
  background-size: cover; */
}
</style>
