<template>
  <v-container class="home elevation-4" id="anchorTop">
    <title-block />
    <subtitle-block />
    <solutions-block />
    <news-block />
    <footer-block />
  </v-container>
</template>
<style>
#anchorTop{
    scroll-margin: 40px;
}

.subtitle-text {
  font-size: 16px!important;
  font-weight: 400!important;
}
.home-desc_xl {
  color: white;
  background-color: #20B4BD!important;
  padding-top: 160px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.home-desc_lg {
  color: white;
  background-color: #20B4BD!important;
  padding-top: 10px;
  padding-bottom: 160px;
  padding-left: 70px;
  margin-top: -2px;
}
.home-desc-title {
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.home-desc-info {
  padding-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.home-subtitle-block {
  background-color: #FBFBF2;
  padding: 16px;
  border-radius: 8px;
  height: 100%;
}
</style>
<script>
import TitleBlock from "@/components/TitleBlock";
import SubtitleBlock from "@/components/SubtitleBlock";
import SolutionsBlock from "@/components/SolutionsBlock";
import NewsBlock from "@/components/NewsBlock";
import FooterBlock from "@/components/FooterBlock";

export default {
  name: "Home",
  components: { TitleBlock, SubtitleBlock, SolutionsBlock, NewsBlock, FooterBlock},
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    check_cookie_name: function (name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return "null";
    },
  },
};
</script>
<style lang="scss">
.home {
  max-width: 1920px !important;
  background-color: white;
}
</style>
